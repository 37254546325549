<template>
  <div id="slider">
    <div class="container-fluid">
      <agile
        v-if="banners.length"
        :center-mode="true"
        :speed="1000"
        :nav-buttons="false"
        :autoplay="false"
      >
        <template v-for="banner in banners">
          <div v-if="isIframe(banner)" :key="banner.id" class="slide">
            <iframe
              v-resize="{ checkOrigin: false, log: true }"
              frameborder="0"
              :src="getUrl(banner)"
            />
          </div>
          <div v-else :key="banner.id" :style="getImageStyle(banner)" class="slide image"></div>
        </template>
      </agile>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
export default {
  name: 'Slider',
  components: {
    agile: VueAgile
  },
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    banners() {
      return this.$store.getters['partner/banners']
    }
  },
  watch: {
    partnerId: 'load'
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      return this.$store.dispatch('partner/banners', { id: this.partnerId })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    getUrl(banner) {
      return `${this.$config.infoSiteUrl}/banner/${banner.url}`
    },

    getImageStyle(banner) {
      // :src="$resize(banner.image.raw.url, { w: 399, h: 266, f: 'cover', c: 'grey' })"
      const image = this.$resize(banner.image.raw.url, { w: 1643, h: 736, f: 'inside', c: 'grey' })
      return {
        backgroundImage: `url(${image})`
      }
    },

    isIframe(banner) {
      return !!banner.url
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

::v-deep .prev,
::v-deep .next {
  color: transparent !important;
  width: 30px !important;
  height: 70px !important;
  background-size: contain !important;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6)) !important;
  cursor: pointer;
  @include media-breakpoint-up(lg) {
    width: 70px !important;
  }
}
::v-deep .prev {
  background: url("~@/assets/slider-prev-white.svg") no-repeat;
  left: calc(20% - 170px);
}

::v-deep .next {
  background: url("~@/assets/slider-next-white.svg") no-repeat;
  right: calc(20% - 180px);
}

#slider {
  position: relative;
  background-color: #86b83e;
  min-height: 400px;
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    min-height: 700px;
  }

  .container-fluid {
    padding: 0;
  }
}

.slide {
  height: 400px !important;

  @include media-breakpoint-up(md) {
    height: 500px !important;
  }

  @media (min-width: 1024px) {
    height: 700px !important;
  }

  iframe {
    border: 0;
    width: 100%;
    min-height: 400px;

    @include media-breakpoint-up(md) {
      min-height: 500px;
    }

    @media (min-width: 1024px) {
      min-height: 700px;
    }

    #player {
      max-height: 400px !important;

      @include media-breakpoint-up(md) {
        max-height: 500px !important;
      }

      @media (min-width: 1024px) {
        max-height: 700px !important;
      }
    }
  }

  &.image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 400px;

    @include media-breakpoint-up(md) {
      min-height: 500px;
    }

    @media (min-width: 1024px) {
      min-height: 700px;
    }
  }

  img.d-lg-flex {
    width: 100%;
  }
}

.slide-item {
  display: flex;
  position: relative;
  justify-content: center;
}
.slide-item-content {
  max-width: 650px;
  position: relative;

  @include media-breakpoint-up(lg) {
    max-width: 1000px;
  }
}
.slide-content {
  background-color: white;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0px 1px 7px #0000005e;
  min-height: 185px;
  margin: 50px 20px 100px;
  position: relative;

  @include media-breakpoint-up(md) {
    min-height: 337px;
    margin: 80px 20px 100px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 450px;
    margin: 100px 0;
  }
}
</style>
